<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">

                                <v-autocomplete
                                    v-model="cursoModel.persona_id"
                                    :items="personasArray"
                                    :loading="loading"
                                    hide-no-data
                                    :search-input.sync="personasSearch"
                                    :item-text="getAutoCompletePersonasText"
                                    item-value="persona_id"
                                    :label="cursoForm.persona_id.label"
                                    :placeholder="cursoForm.persona_id.placeholder"
                                    :rules="cursoForm.persona_id.rules"
                                    prepend-icon="perm_identity"
                                    required
                                ></v-autocomplete>

                                <v-text-field
                                    v-model="cursoModel.registro"
                                    :label="cursoForm.registro.label"
                                    :placeholder="cursoForm.registro.placeholder"
                                    prepend-icon="pin"
                                />

                                <v-text-field
                                    v-model="cursoModel.nombre"
                                    :label="cursoForm.nombre.label"
                                    :placeholder="cursoForm.nombre.placeholder"
                                    :rules="cursoForm.nombre.rules"
                                    required
                                    prepend-icon="perm_identity"
                                />

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            type="number"
                                            v-model="cursoModel.anyo"
                                            :label="cursoForm.anyo.label"
                                            :placeholder="cursoForm.anyo.placeholder"
                                            :rules="cursoForm.anyo.rules"
                                            required
                                            prepend-icon="school"
                                        />
                                        <v-text-field
                                            type="number"
                                            v-model="cursoModel.horas_curso"
                                            :label="cursoForm.horas_curso.label"
                                            :placeholder="cursoForm.horas_curso.placeholder"
                                            :rules="cursoForm.horas_curso.rules"
                                            required
                                            prepend-icon="schedule"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            type="number"
                                            v-model="cursoModel.promocion"
                                            :label="cursoForm.promocion.label"
                                            :placeholder="cursoForm.promocion.placeholder"
                                            :rules="cursoForm.promocion.rules"
                                            required
                                            prepend-icon="school"
                                        />

                                        <v-text-field
                                            type="number"
                                            v-model="cursoModel.nota"
                                            :label="cursoForm.nota.label"
                                            :placeholder="cursoForm.nota.placeholder"
                                            :rules="cursoForm.nota.rules"
                                            required
                                            prepend-icon="money"
                                        />
                                    </v-col>
                                </v-row>

                                <v-text-field
                                    v-model="cursoModel.tutor"
                                    :label="cursoForm.tutor.label"
                                    :placeholder="cursoForm.tutor.placeholder"
                                    :rules="cursoForm.tutor.rules"
                                    required
                                    prepend-icon="record_voice_over"
                                />

                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">

                                <v-select
                                    v-model="cursoModel.unidad_operativa_id"
                                    :items="unidadesOperativasArray"
                                    item-text="unidad_operativa"
                                    item-value="unidad_operativa_id"
                                    :label="cursoForm.unidad_operativa_id.label"
                                    :placeholder="cursoForm.unidad_operativa_id.placeholder"
                                    :rules="cursoForm.unidad_operativa_id.rules"
                                    prepend-icon="business"
                                    required
                                ></v-select>

                                <v-text-field
                                    type="date"
                                    v-model="cursoModel.fecha_inicio"
                                    :label="cursoForm.fecha_inicio.label"
                                    :placeholder="cursoForm.fecha_inicio.placeholder"
                                    :rules="cursoForm.fecha_inicio.rules"
                                    required
                                    prepend-icon="calendar_month"
                                />

                                <v-text-field
                                    type="date"
                                    v-model="cursoModel.fecha_finalizacion"
                                    :label="cursoForm.fecha_finalizacion.label"
                                    :placeholder="cursoForm.fecha_finalizacion.placeholder"
                                    :rules="cursoForm.fecha_finalizacion.rules"
                                    required
                                    prepend-icon="calendar_month"
                                />

                                <v-text-field
                                    type="date"
                                    v-model="cursoModel.fecha_certificado"
                                    :label="cursoForm.fecha_certificado.label"
                                    :placeholder="cursoForm.fecha_certificado.placeholder"
                                    prepend-icon="calendar_month"
                                />

                                <v-text-field
                                    v-model="cursoModel.convenio"
                                    :label="cursoForm.convenio.label"
                                    :placeholder="cursoForm.convenio.placeholder"
                                    prepend-icon="feed"
                                />

                                <v-text-field
                                    v-model="cursoModel.observaciones"
                                    :label="cursoForm.observaciones.label"
                                    prepend-icon="chat"
                                />

                                <v-row>
                                    <v-col cols="3">
                                        <!-- Que solo sea visible desde Editar-->
                                        <v-switch v-show="$route.query.token"
                                                  v-model="cursoModel.activo"
                                                  :label="cursoForm.activo.label"
                                                  color="success"
                                                  class="font-weight-bold"
                                                  inset
                                                  large
                                                  hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="9">
                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined small @click="regresar">
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined small color="warning" @click="reset">
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn outlined small color="error" @click="deleteBefore" v-if="permisos[4] && cursoModel.curso_id">
                        <v-icon>delete</v-icon>
                        Eliminar
                    </v-btn>

                    <v-btn color="primary" small :loading="loading" @click="cursoSave" >
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-bottom-sheet
            v-model="deleteConfirm"
            persistent
        >
            <v-sheet
                class="text-center"
                height="200"
            >

                <div class="py-4">
                    <h2 class="red--text mb-3">¿Esta realmente seguro de ELIMINAR este Curso.?</h2>
                    <h4> Esta operación es irreversible. El estudiante ya no podrá este Curso en su listado.</h4>
                </div>
                <v-card-actions class="justify-center pb-3">
                    <v-btn outlined small @click="deleteConfirm = false">
                        <v-icon>hide_source</v-icon>
                        No lo eliminaré
                    </v-btn>
                    <v-btn outlined small color="error" @click="cursoDelete">
                        <v-icon>delete</v-icon>
                        Si, Estoy Seguro de Eliminar
                    </v-btn>
                </v-card-actions>

            </v-sheet>
        </v-bottom-sheet>

    </v-row>

</template>

<script>

export default {
    name: "CursosFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            permisos: this.getParams.permisos,
            unidadesOperativasArray: [],
            personasArray: [],
            personasSearch: null,
            deleteConfirm: false,
            cursoModel: {
                curso_id: null,
                persona_id: null,
                nombre: null,
                promocion: null,
                nota: null,
                horas_curso: null,
                registro: null,
                fecha_inicio: null,
                fecha_finalizacion: null,
                fecha_certificado: null,
                tutor: null,
                anyo: null,
                convenio: null,
                observaciones: null,
                unidad_operativa_id: null,
                activo: true,
            },
            cursoForm: {
                persona_id: {
                    label: 'Participante',
                    placeholder: 'Escriba para buscar por Identidad, Nombres, Apellidos',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                nombre: {
                    label: 'Nombre del Curso',
                    placeholder: 'Nombre del Curso',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                promocion: {
                    label: 'Promoción',
                    placeholder: 'Promoción',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                nota: {
                    label: 'Nota final',
                    placeholder: 'Nota final',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                horas_curso: {
                    label: 'Horas curso',
                    placeholder: 'Horas curso',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                registro: {
                    label: 'Número de Registro',
                    placeholder: 'Número de Registro',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                fecha_inicio: {
                    label: 'Fecha de inicio',
                    placeholder: 'Fecha de inicio',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                fecha_finalizacion: {
                    label: 'Fecha de finalización',
                    placeholder: 'Fecha de finalización',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                fecha_certificado: {
                    label: 'Fecha de certificación',
                    placeholder: 'Fecha de certificación',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                tutor: {
                    label: 'Tutor',
                    placeholder: 'Tutor',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                anyo: {
                    label: 'Año del curso',
                    placeholder: 'Año del curso',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                convenio: {
                    label: 'Convenio',
                    placeholder: 'Convenio',
                },
                observaciones: {
                    label: 'Observaciones',
                    placeholder: 'Observaciones',
                },
                unidad_operativa_id: {
                    label: 'Unidad Operativa',
                    placeholder: 'Seleccione',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                activo: {
                    label: 'Activo',
                },
            },

        }
    },
    watch: {
        personasSearch(busqueda) {
            if (this.loading) return
            // Buscar si tiene mas de 3 caracteres
            if (typeof busqueda !== "undefined" && busqueda.length >= 3 && busqueda.length < 20) {
                this.loading = true

                let params = {
                    cat_tipo_persona_id: 3,
                    busqueda: busqueda
                };

                // Get data;
                window.axios.get('/personas/search', {params}).then(response => {
                    if (response.data.success) {

                        this.personasArray = response.data.data;

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener los datos para continuar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false
                    })

            }
        }
    },
    methods: {
        getAutoCompletePersonasText(persona) {
            return `${persona.numero_identidad} | ${persona.nombre} ${persona.apellido}`;
        },
        goToEdit() {
            let token = window.btoa(this.cursoModel.curso_id);
            this.$router.push({name: 'CursosEditar', query: {token}});
        },
        regresar() {
            // Regresar a la anterior vista
            this.$router.go(-1)
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.cursoModel.curso_id)) {
                this.getCursoEdit();
            } else {
                this.$refs.form.reset();
            }
        },
        deleteBefore() {
            // Activar el DELETE
            this.deleteConfirm = true;
        },
        getCursoCreate() {
            this.loading = true;

            // Get data;
            window.axios.get('/cursos/create', {}).then(response => {
                if (response.data.success) {

                    this.unidadesOperativasArray = response.data.data.unidades_operativas;

                    // Si vienen los datos, llamar al edit
                    this.getCursoEdit();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        getCursoEdit() {

            // Validar operaciones
            if (Number(this.getParams.curso_id)) {
                this.loading = true;
                this.cursoModel.curso_id = this.getParams.curso_id;

                let params = {curso_id: this.cursoModel.curso_id}

                window.axios.get('/cursos/edit', {params}).then(response => {
                    if (response.data.success) {

                        if (response.data.data.length) {
                            this.cursoModel = response.data.data[0];
                            this.personasArray = [{
                                persona_id: this.cursoModel.persona_id,
                                nombre: this.cursoModel.estudiante_nombre,
                                apellido: this.cursoModel.estudiante_apellido,
                                numero_identidad: this.cursoModel.estudiante_identidad,
                            }];
                        } else {
                            this.$toast.error("No se encontraron los datos del Curso.");
                        }

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener la información del Curso.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            }
        },
        cursoSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.cursoModel.curso_id)) ? '/cursos/update' : '/cursos/store';


                window.axios.post(url, this.cursoModel).then(response => {
                    if (response.data.success) {

                        this.$toast.success(response.data.message);

                        // Regresar, si es Editar
                        if (Number(this.cursoModel.curso_id)) {
                            // NO HACE NADA QUE SE QUEDE AHI
                        } else {
                            // Resetear el form y que vuelva a guardar
                            this.cursoModel.curso_id = response.data.data;
                            this.goToEdit();
                        }


                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }

        },
        cursoDelete() {
            // Validar
            let url = '/cursos/delete';
            window.axios.post(url, this.cursoModel).then(response => {
                if (response.data.success) {

                    this.$toast.success(response.data.message);

                    this.regresar();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo Eliminar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
    },
    created() {
        this.getCursoCreate();
    }
}
</script>

<style>

</style>
